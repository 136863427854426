.WorkInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(0, 0, 0, 0.4);
}

.WorkInfo .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 2em);
  max-width: 500px;
  max-height: calc(100% - 2em);
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.WorkInfo header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #252222;
  padding: 1em;
}

.WorkInfo .body {
  background: #ffffff;
  color: #252222;
  padding: 10px 40px 30px;
}
