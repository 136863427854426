.Header {
  background: #252222;
}

.Header .row {
  align-items: center;
}

.Header nav ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
}

.Header nav li.show-tablet {
  display: none;
}

.Header nav a {
  display: block;
  position: relative;
  padding: 20px 15px;
}

.Header nav li:last-child a {
  padding-right: 0;
}

.Header nav a:before {
  content: '';
  position: absolute;
  pointer-events: none;
  opacity: 0;
  bottom: 15px;
  left: 10px;
  right: 10px;
  height: 3px;
  background: currentColor;
  transition: bottom 0.2s ease, opacity 0.2s ease;
}

.Header nav li:last-child a:before {
  right: -5px;
}

.Header nav a:hover:before {
  bottom: 10px;
  opacity: 1;
}

@media (min-width: 768px) {
  .Header {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .Header .logo {
    font-size: 3.35rem;
  }

  .Header nav li.show-tablet {
    display: list-item;
  }
}
