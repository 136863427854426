.Contact {
  position: relative;
  background: url('/img/contact.jpg') center center;
  background-size: cover;
  background-color: #252222;
}

.Contact:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.Contact .contact-content {
  padding-top: 2em;
  padding-bottom: 2em;
}

.Contact .contact-content .h2,
.Contact .contact-content .h3 {
  font-weight: normal;
  line-height: 1.4;
  padding-bottom: 0.4em;
}

.Contact .mail-block a {
  display: block;
  max-width: 9em;
  margin: auto;
  border: 4px solid currentColor;
}

.Contact p {
  margin: 0;
}

.Contact footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

@media (min-width: 768px) {
  .Contact .mail-block a {
    font-size: 1.3em;
  }
}
