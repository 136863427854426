@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/bebasneue_regular-webfont.woff'),
    url('/fonts/bebasneue_regular-webfont.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/bebasneue_bold-webfont.woff'),
    url('/fonts/bebasneue_bold-webfont.woff2');
  font-weight: 700;
  font-style: normal;
}
