html {
  font-family: 'Open Sans';
  font-size: 12px;
  color: #ffffff;
}

body {
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

h1,
.h1 {
  font-family: 'Bebas Neue';
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

h2,
.h2 {
  font-family: 'Bebas Neue';
  font-size: 3.3rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

h3,
.h3 {
  font-family: 'Bebas Neue';
  font-size: 2.6rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

h4,
.h4 {
  font-family: 'Bebas Neue';
  font-size: 1.71rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

h5,
.h5 {
  font-family: 'Bebas Neue';
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

h6,
.h6 {
  font-family: 'Bebas Neue';
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 16px;
}

ul li {
  position: relative;
  list-style: none;
}

hgroup {
  padding-bottom: 45px;
}

figure {
  margin: 0;
}

.icon {
  height: 1em;
  fill: currentColor;
}

.link-default {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

.link-default:hover {
  color: #65ba99;
}

.block {
  display: block;
}

.text-left {
  text-align: left;
}

.page-section {
  padding-top: 47px;
  padding-bottom: 80px;
  text-align: center;
}

.page-section__title:after {
  content: ' /';
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }

  h1,
  .h1 {
    font-size: 6.43rem;
  }
}
