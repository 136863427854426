@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.Hero {
  position: relative;
  padding-top: 125px;
  padding-bottom: 125px;
  background: url('/img/header.jpg') center center;
  background-size: cover;
  background-color: #252222;
  line-height: 0.75;
}

.Hero:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.Hero hgroup {
  position: relative;
  padding-bottom: 1.9rem;
}

.Hero .title-main span {
  display: block;
}

.Hero .rewrittable:after {
  content: '_';
  -webkit-animation: fadeToggle 1.5s infinite;
          animation: fadeToggle 1.5s infinite;
}

@-webkit-keyframes fadeToggle {
  0% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}

@keyframes fadeToggle {
  0% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}

.Header {
  background: #252222;
}

.Header .row {
  align-items: center;
}

.Header nav ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
}

.Header nav li.show-tablet {
  display: none;
}

.Header nav a {
  display: block;
  position: relative;
  padding: 20px 15px;
}

.Header nav li:last-child a {
  padding-right: 0;
}

.Header nav a:before {
  content: '';
  position: absolute;
  pointer-events: none;
  opacity: 0;
  bottom: 15px;
  left: 10px;
  right: 10px;
  height: 3px;
  background: currentColor;
  -webkit-transition: bottom 0.2s ease, opacity 0.2s ease;
  transition: bottom 0.2s ease, opacity 0.2s ease;
}

.Header nav li:last-child a:before {
  right: -5px;
}

.Header nav a:hover:before {
  bottom: 10px;
  opacity: 1;
}

@media (min-width: 768px) {
  .Header {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .Header .logo {
    font-size: 3.35rem;
  }

  .Header nav li.show-tablet {
    display: list-item;
  }
}

.WorkInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(0, 0, 0, 0.4);
}

.WorkInfo .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 2em);
  max-width: 500px;
  max-height: calc(100% - 2em);
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
}

.WorkInfo header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #252222;
  padding: 1em;
}

.WorkInfo .body {
  background: #ffffff;
  color: #252222;
  padding: 10px 40px 30px;
}

.WorkPreview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(0, 0, 0, 0.6);
}

.WorkPreview .shake {
  -webkit-animation: shake 2s infinite;
          animation: shake 2s infinite;
}

.WorkPreview .toggle-view {
  position: absolute;
  top: calc(50% - 120px);
  right: calc(50% + 120px);
  padding: 10px;
  font-size: 2.67em;
  z-index: 1;
}

.WorkPreview .toggle-view .bottom,
.WorkPreview .toggle-view .square {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.WorkPreview:not(.mobile) .toggle-view .bottom {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: none;
  transition: none;
}

.WorkPreview:not(.mobile) .toggle-view .square {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.WorkPreview .toggle-view .circle {
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.WorkPreview.mobile .toggle-view .circle {
  opacity: 0;
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

.WorkPreview .close {
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% + 120px);
  padding: 10px;
  font-size: 1.7em;
  z-index: 1;
}

.WorkPreview .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 320px;
  height: 174px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center center;
}

.WorkPreview.mobile .modal {
  width: 159px;
  height: 280px;
}

.WorkPreview .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 220px;
  height: 138px;
  margin-top: -5px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow-y: scroll;
}

.WorkPreview.mobile .inner {
  width: 141px;
  height: 217px;
  margin-top: -6px;
}

.WorkPreview .inner img {
  display: block;
  width: 100%;
}

.WorkPreview .inner::-webkit-scrollbar {
  width: 5px;
}

.WorkPreview .inner::-webkit-scrollbar-track {
  background: #ffffff;
}

.WorkPreview .inner::-webkit-scrollbar-thumb {
  background: #909090;
}

@media (min-width: 768px) {
  .WorkPreview .toggle-view {
    top: calc(50% - 23.5vw);
    right: 76.5vw;
  }

  .WorkPreview .close {
    top: calc(50% - 23.5vw);
    left: 76.5vw;
  }

  .WorkPreview .modal {
    width: 70.2vw;
    height: 38.5vw;
    max-height: 500px;
    max-width: 910px;
  }

  .WorkPreview.mobile .modal {
    width: 25.5vw;
    height: 45vw;
    max-height: 588px;
    max-width: 333px;
  }

  .WorkPreview .inner {
    width: 49.6vw;
    height: 31vw;
    margin-top: -1.1vw;
  }

  .WorkPreview.mobile .inner {
    width: 22.8vw;
    height: 34.7vw;
    margin-top: -0.8vw;
  }
}

@media (min-width: 1305px) {
  .WorkPreview .toggle-view {
    top: calc(50% - 307px);
    right: calc(50% + 345px);
  }

  .WorkPreview .close {
    top: calc(50% - 307px);
    left: calc(50% + 345px);
  }

  .WorkPreview .inner {
    margin-top: -14px;
    width: 653px;
    height: 405px;
  }

  .WorkPreview.mobile .inner {
    margin-top: -11px;
    width: 298px;
    height: 454px;
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  10% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  15% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  20% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  10% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  15% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  20% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.Works {
  background: #65ba99;
}

.Works figure {
  position: relative;
  margin: 0 auto;
  background-size: cover;
  background-position: center center;
  height: 255px;
  max-width: 470px;
}

.Works figcaption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.5);
}

.Works .work-title {
  position: absolute;
  top: 1.3em;
  left: 1em;
  right: 1em;
}

.Works .icon-wrapper {
  color: unset;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .Works .work:nth-child(odd) {
    padding-right: 0;
  }

  .Works .work:nth-child(even) {
    padding-left: 0;
  }

  .Works .work:nth-child(odd) figure {
    margin-right: 0;
  }

  .Works .work:nth-child(even) figure {
    margin-left: 0;
  }
}

@media (hover: hover) {
  .Works figcaption {
    opacity: 0;
    background: rgba(101, 186, 153, 0.85);
  }

  .Works figure:hover figcaption {
    opacity: 1;
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
  }
}

.Bio {
  background-color: #e2534b;
}

.Bio .page-section__title {
  color: #252222;
}

.Bio .bio-content {
  color: #ffffff;
}

.Bio .bio-photo {
  border-radius: 100%;
  margin: 1.5em 0;
}

.Bio .bio-links {
  font-size: 1.4em;
}

.Bio .bio-links a {
  margin: 0 0.25em;
}

.Bio .bio-text {
  padding-top: 2em;
}

@media (hover: hover) {
  .Bio .bio-photo {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    -webkit-transition: -webkit-filter 0.2s ease;
    transition: -webkit-filter 0.2s ease;
    transition: filter 0.2s ease;
    transition: filter 0.2s ease, -webkit-filter 0.2s ease;
  }

  .Bio .bio-photo:hover {
    -webkit-filter: none;
            filter: none;
  }
}

@media (min-width: 768px) {
  .Bio .bio-text {
    padding-top: 0;
    text-align: left;
  }
}

.Contact {
  position: relative;
  background: url('/img/contact.jpg') center center;
  background-size: cover;
  background-color: #252222;
}

.Contact:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.Contact .contact-content {
  padding-top: 2em;
  padding-bottom: 2em;
}

.Contact .contact-content .h2,
.Contact .contact-content .h3 {
  font-weight: normal;
  line-height: 1.4;
  padding-bottom: 0.4em;
}

.Contact .mail-block a {
  display: block;
  max-width: 9em;
  margin: auto;
  border: 4px solid currentColor;
}

.Contact p {
  margin: 0;
}

.Contact footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

@media (min-width: 768px) {
  .Contact .mail-block a {
    font-size: 1.3em;
  }
}

html {
  font-family: 'Open Sans';
  font-size: 12px;
  color: #ffffff;
}

body {
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

h1,
.h1 {
  font-family: 'Bebas Neue';
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

h2,
.h2 {
  font-family: 'Bebas Neue';
  font-size: 3.3rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

h3,
.h3 {
  font-family: 'Bebas Neue';
  font-size: 2.6rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

h4,
.h4 {
  font-family: 'Bebas Neue';
  font-size: 1.71rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

h5,
.h5 {
  font-family: 'Bebas Neue';
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

h6,
.h6 {
  font-family: 'Bebas Neue';
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 16px;
}

ul li {
  position: relative;
  list-style: none;
}

hgroup {
  padding-bottom: 45px;
}

figure {
  margin: 0;
}

.icon {
  height: 1em;
  fill: currentColor;
}

.link-default {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}

.link-default:hover {
  color: #65ba99;
}

.block {
  display: block;
}

.text-left {
  text-align: left;
}

.page-section {
  padding-top: 47px;
  padding-bottom: 80px;
  text-align: center;
}

.page-section__title:after {
  content: ' /';
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }

  h1,
  .h1 {
    font-size: 6.43rem;
  }
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/bebasneue_regular-webfont.woff'),
    url('/fonts/bebasneue_regular-webfont.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/bebasneue_bold-webfont.woff'),
    url('/fonts/bebasneue_bold-webfont.woff2');
  font-weight: 700;
  font-style: normal;
}

