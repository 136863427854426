.Bio {
  background-color: #e2534b;
}

.Bio .page-section__title {
  color: #252222;
}

.Bio .bio-content {
  color: #ffffff;
}

.Bio .bio-photo {
  border-radius: 100%;
  margin: 1.5em 0;
}

.Bio .bio-links {
  font-size: 1.4em;
}

.Bio .bio-links a {
  margin: 0 0.25em;
}

.Bio .bio-text {
  padding-top: 2em;
}

@media (hover: hover) {
  .Bio .bio-photo {
    filter: grayscale(100%);
    transition: filter 0.2s ease;
  }

  .Bio .bio-photo:hover {
    filter: none;
  }
}

@media (min-width: 768px) {
  .Bio .bio-text {
    padding-top: 0;
    text-align: left;
  }
}
