.WorkPreview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: rgba(0, 0, 0, 0.6);
}

.WorkPreview .shake {
  animation: shake 2s infinite;
}

.WorkPreview .toggle-view {
  position: absolute;
  top: calc(50% - 120px);
  right: calc(50% + 120px);
  padding: 10px;
  font-size: 2.67em;
  z-index: 1;
}

.WorkPreview .toggle-view .bottom,
.WorkPreview .toggle-view .square {
  transition: transform 0.4s ease;
  transform-origin: center center;
}

.WorkPreview:not(.mobile) .toggle-view .bottom {
  transform: scale(0);
  transition: none;
}

.WorkPreview:not(.mobile) .toggle-view .square {
  transform: rotate(90deg);
}

.WorkPreview .toggle-view .circle {
  transition: opacity 0.4s ease;
  transition-delay: 0.1s;
}

.WorkPreview.mobile .toggle-view .circle {
  opacity: 0;
  transition-delay: 0;
}

.WorkPreview .close {
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% + 120px);
  padding: 10px;
  font-size: 1.7em;
  z-index: 1;
}

.WorkPreview .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 320px;
  height: 174px;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center center;
}

.WorkPreview.mobile .modal {
  width: 159px;
  height: 280px;
}

.WorkPreview .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 220px;
  height: 138px;
  margin-top: -5px;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}

.WorkPreview.mobile .inner {
  width: 141px;
  height: 217px;
  margin-top: -6px;
}

.WorkPreview .inner img {
  display: block;
  width: 100%;
}

.WorkPreview .inner::-webkit-scrollbar {
  width: 5px;
}

.WorkPreview .inner::-webkit-scrollbar-track {
  background: #ffffff;
}

.WorkPreview .inner::-webkit-scrollbar-thumb {
  background: #909090;
}

@media (min-width: 768px) {
  .WorkPreview .toggle-view {
    top: calc(50% - 23.5vw);
    right: 76.5vw;
  }

  .WorkPreview .close {
    top: calc(50% - 23.5vw);
    left: 76.5vw;
  }

  .WorkPreview .modal {
    width: 70.2vw;
    height: 38.5vw;
    max-height: 500px;
    max-width: 910px;
  }

  .WorkPreview.mobile .modal {
    width: 25.5vw;
    height: 45vw;
    max-height: 588px;
    max-width: 333px;
  }

  .WorkPreview .inner {
    width: 49.6vw;
    height: 31vw;
    margin-top: -1.1vw;
  }

  .WorkPreview.mobile .inner {
    width: 22.8vw;
    height: 34.7vw;
    margin-top: -0.8vw;
  }
}

@media (min-width: 1305px) {
  .WorkPreview .toggle-view {
    top: calc(50% - 307px);
    right: calc(50% + 345px);
  }

  .WorkPreview .close {
    top: calc(50% - 307px);
    left: calc(50% + 345px);
  }

  .WorkPreview .inner {
    margin-top: -14px;
    width: 653px;
    height: 405px;
  }

  .WorkPreview.mobile .inner {
    margin-top: -11px;
    width: 298px;
    height: 454px;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(20deg);
  }
  10% {
    transform: rotate(-20deg);
  }
  15% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  25% {
    transform: rotate(0deg);
  }
}
