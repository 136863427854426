.Works {
  background: #65ba99;
}

.Works figure {
  position: relative;
  margin: 0 auto;
  background-size: cover;
  background-position: center center;
  height: 255px;
  max-width: 470px;
}

.Works figcaption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.5);
}

.Works .work-title {
  position: absolute;
  top: 1.3em;
  left: 1em;
  right: 1em;
}

.Works .icon-wrapper {
  color: unset;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .Works .work:nth-child(odd) {
    padding-right: 0;
  }

  .Works .work:nth-child(even) {
    padding-left: 0;
  }

  .Works .work:nth-child(odd) figure {
    margin-right: 0;
  }

  .Works .work:nth-child(even) figure {
    margin-left: 0;
  }
}

@media (hover: hover) {
  .Works figcaption {
    opacity: 0;
    background: rgba(101, 186, 153, 0.85);
  }

  .Works figure:hover figcaption {
    opacity: 1;
    transition: opacity 0.4s ease;
  }
}
