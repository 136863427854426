.Hero {
  position: relative;
  padding-top: 125px;
  padding-bottom: 125px;
  background: url('/img/header.jpg') center center;
  background-size: cover;
  background-color: #252222;
  line-height: 0.75;
}

.Hero:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.Hero hgroup {
  position: relative;
  padding-bottom: 1.9rem;
}

.Hero .title-main span {
  display: block;
}

.Hero .rewrittable:after {
  content: '_';
  animation: fadeToggle 1.5s infinite;
}

@keyframes fadeToggle {
  0% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}
